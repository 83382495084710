import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

const ApiDocumentation = () => {
  return (
    <div>
      <SwaggerUI url={process.env.PUBLIC_URL + '/openapi.yaml'} />
    </div>
  );
};

export default ApiDocumentation;